'use client'


import { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import Image, { type StaticImageData } from 'next/image'

import image0 from 'public/images/avatars/avatar-0.jpg'
import image1 from 'public/images/avatars/avatar-1.jpg'
import image2 from 'public/images/avatars/avatar-2.jpg'
import image3 from 'public/images/avatars/avatar-3.jpg'
import image4 from 'public/images/avatars/avatar-4.jpg'
import image5 from 'public/images/avatars/avatar-5.jpg'
import image6 from 'public/images/avatars/avatar-6.jpg'
import image7 from 'public/images/avatars/avatar-7.jpg'


type Index = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7

const items: StaticImageData[] = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
]


type RandomAvatarProps = {
  className?: string
  selection: Index[]
  initialBgRotateDeg?: 0 | 45 | 90 | 135 | 180 | 270
  rotateReverse?: boolean
}

const RandomAvatar: React.FC<RandomAvatarProps> = (props) => {
  const { className, selection, initialBgRotateDeg = 0, rotateReverse } = props

  const filteredItems = useMemo(() => selection.map((index) => items[index]).filter(Boolean), [ selection.join() ])
  const [ activeIndex, setActiveIndex ] = useState(0)
  const bgRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let deg = initialBgRotateDeg
    const delta = rotateReverse ? -45 : 45
    const delay = 3000 + Math.floor(Math.random() * 1000)

    const timer = setInterval(() => {
      setActiveIndex((index) => {
        deg += delta
        bgRef.current?.style.setProperty('--tw-rotate', `${deg}deg`)

        return index === filteredItems.length - 1 ? 0 : index + 1
      })
    }, delay)

    return () => {
      clearInterval(timer)
    }
  }, [ filteredItems ])

  return (
    <div className={clsx(className, 'relative p-1.5 rounded-full overflow-hidden')}>
      <div ref={bgRef} className="absolute top-0 left-0 rotate-0 size-full bg-gradient-avatar transition-transform ease-in-out-quadratic duration-150" />
      {
        filteredItems.map((image, index) => (
          <div
            className={clsx('transition ease-linear duration-300', activeIndex !== index && 'opacity-0')}
            key={image.src}
          >
            <Image className="absolute top-0 right-0 bottom-0 left-0 size-full p-[6%] rounded-full" src={image} alt="" />
          </div>
        ))
      }
    </div>
  )
}

export default RandomAvatar
