'use client'

import clsx from 'clsx'
import { constants } from 'helpers'

import { Button } from 'components/inputs'
import { VideoOverlay } from 'components/ui'

import { TitleCarousel, Cards } from './components'


type ScreenBuildProps = {
  className?: string
}

const linkClassName = 'text-white hocus:underline underline-offset-1'

const ScreenBuild: React.FC<ScreenBuildProps> = (props) => {
  const { className } = props

  const rootClassName = clsx(
    className,
    'relative z-10 flex flex-col'
  )

  const titleClassName = clsx(
    'mt-16 sm:mt-[25vh] w-full',
    'font-poppins text-[10.25vw] sm:text-[6.5vw] xl:text-[6rem] leading-[0] font-bold uppercase'
  )

  return (
    <section className={rootClassName}>
      <div className="min-h-dvh relative flex-1">
        <VideoOverlay className="absolute left-0 size-full overflow-hidden -z-20 -scale-y-100" />
        <div className="absolute top-0 left-0 w-full h-48 bg-gradient-to-b from-[#030c6a] -z-10" />
        <div className="absolute top-0 left-0 size-full -z-10 bg-deep-blue/20 bg-dots-blue-25 mask-gradient-to-t from-transparent from-25% to-black bg-size-5 bg-repeat bg-top" />
        <div className="relative w-container flex-1 p-4 text-white -sm:pb-16">
          <TitleCarousel className={titleClassName} />
          <div className="will-change-transform sm:transform-gpu duration-1000 delay-500 translate-y-1/2 scale-110 opacity-0 [.section.active_&]:transform-none [.section.active_&]:opacity-100">
            <div className="text-land-h3 ml-1 sm:ml-[0.1em]">
              in 5 minutes
            </div>
            <div className="mt-5 w-full max-w-[44rem] text-azuro-sky text-land-body-1 sm:ml-[0.1em]">
              Develop and launch fully-functional <br className="-sm:hidden" />prediction apps, in minutes.
            </div>
            <div className="flex items-center space-x-3 sm:justify-end mt-7 sm:-mt-2">
              <Button
                className="-sm:flex-1"
                toTab={constants.links.build}
                title="Developer Hub"
                styling="land-white"
                size={48}
              />
              <Button
                className="-sm:flex-1"
                toTab={constants.links.github}
                title="GitHub"
                leftIcon="interface/github"
                styling="land-black"
                size={48}
              />
            </div>
            <Cards className="mt-14 sm:mt-[5vh]" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ScreenBuild
