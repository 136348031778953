'use client'

import clsx from 'clsx'
import { constants } from 'helpers'

import { Button } from 'components/inputs'

import { Title } from './components'


type ScreenGovernProps = {
  className?: string
}

const ScreenGovern: React.FC<ScreenGovernProps> = (props) => {
  const { className } = props

  return (
    <section className={clsx(className, 'relative w-full')}>
      <div className="-sm:min-h-[90dvh]">
        <div className="absolute top-0 left-0 size-full -z-10 bg-dots-blue-25 bg-size-5 bg-bottom mask-gradient-to-t from-black to-95%" />
        <div className="w-container h-[43vh] -sm:pl-4 flex items-end justify-center">
          <Title />
        </div>
        <div className="w-container mt-12 sm:mt-16 pb-12 sm:flex items-end justify-between">
          <p className="text-land-h4 text-grey-90">
            The future of prediction markets is here. <br className="-lg:hidden" />Be part of the movement.
          </p>
          <div className="-sm:mt-6 sm:flex">
            {/*<Button*/}
            {/*  className="mt-3 w-full"*/}
            {/*  title={buttonMessages.learnMore}*/}
            {/*  toTab={constants.links.govern}*/}
            {/*  styling="land-ocean-20"*/}
            {/*  size={56}*/}
            {/*/>*/}
            <Button
              className="mt-3 w-full sm:ml-6"
              title="Join Discord"
              toTab={constants.links.discord}
              leftIcon="interface/discord"
              styling="land-black"
              size={56}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ScreenGovern
