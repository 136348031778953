import Image, { type StaticImageData } from 'next/image'
import image0 from 'public/images/land-odds/odds-0.png'
import image1 from 'public/images/land-odds/odds-1.png'
import image2 from 'public/images/land-odds/odds-2.png'
import image3 from 'public/images/land-odds/odds-3.png'
import image4 from 'public/images/land-odds/odds-4.png'
import image5 from 'public/images/land-odds/odds-5.png'

import { ScrollingBar } from 'components/dataDisplay'


type Item = {
  type: 'image'
  src: StaticImageData
  height?: string
} | {
  type: 'odds'
  text: string
}

const items: Item[] = [
  {
    type: 'image',
    src: image0,
    height: '6.95vh',
  },
  {
    type: 'odds',
    // text: '2 : 1.2',
    text: '2:1',
  },
  {
    type: 'image',
    src: image1,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '1.5:2',
  },
  {
    type: 'image',
    src: image2,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '2:3',
  },
  {
    type: 'image',
    src: image3,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '1:3',
  },
  {
    type: 'image',
    src: image4,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '1:2',
  },
  {
    type: 'image',
    src: image5,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '2.5:1',
  },
  {
    type: 'image',
    src: image0,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '2:3',
  },
  {
    type: 'image',
    src: image2,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '3.50',
  },
  {
    type: 'image',
    src: image3,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '2.20',
  },
  {
    type: 'image',
    src: image5,
    height: '6.95vh',
  },
  {
    type: 'odds',
    text: '3.20',
  },
]

type SlotsOddsBarProps = {
  isVisible?: boolean
}

const SlotsOddsBar: React.FC<SlotsOddsBarProps> = (props) => {
  const { isVisible } = props

  return (
    <ScrollingBar
      contentClassName="flex items-center h-[15vh] text-white font-medium text-land-h3 space-x-7 sm:space-x-16"
      roundDurationSec={24}
      isVisible={isVisible}
    >
      {
        items.map((item, index) => {
          const { type } = item

          if (type === 'image') {
            const { src, height } = item

            return (
              <Image
                key={index}
                className="w-auto -sm:scale-75"
                src={src}
                alt=""
                style={{ height }}
              />
            )
          }

          return (
            <span key={item.text}>{item.text}</span>
          )
        })
      }
    </ScrollingBar>
  )
}

export default SlotsOddsBar
