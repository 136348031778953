'use client'

import clsx from 'clsx'


type TitleProps = {
  isVisible: boolean
}

const Title: React.FC<TitleProps> = (props) => {
  const { isVisible } = props

  const animationClassName = clsx(
    'will-change-transform sm:transform-gpu duration-1000 opacity-0 translate-y-full',
    isVisible && '[.section.active_&]:transform-none [.section.active_&]:opacity-100'
  )

  return (
    <h1 className="w-container w-full flex sm:justify-between -sm:flex-col font-poppins text-[3.25rem] sm:text-[8.25vw] xl:text-[7.75rem] font-bold text-grey-90 leading-none tracking-tighter uppercase">
      <span className={animationClassName}>
        The
      </span>
      <em className={clsx('bg-back-animated bg-cover bg-clip-text text-transparent pr-1.5 delay-100 -translate-x-12', animationClassName)}>
        Predictions
      </em>
      <span className={clsx('delay-200 -translate-x-12', animationClassName)}>
        L<span className="-tracking-[0.15em]">a</span>yer
      </span>
    </h1>
  )
}

export default Title
