'use client'
import { type ReactNode, useEffect, useId } from 'react'
import { useEntry } from '@locmod/intersection-observer'
import clsx from 'clsx'
import Image, { type StaticImageData } from 'next/image'

import { ScrollingBar } from 'components/dataDisplay'

import bettorsImage from './images/bg-bettors.png'
import oddsImage from './images/bg-odds.png'
import liquidityImage from './images/bg-liquidity.png'
import liquidityGlowImage from './images/bg-liquidity-glow.png'


type Item = {
  imageClassName?: string
  title: string
  value: string
} & AtLeastOne<{
  image: StaticImageData
  imageNode: ReactNode
}>

const animationClassName = 'will-change-transform sm:transform-gpu duration-1000 [.section.active_&]:transform-none [.section.active_&]:opacity-100'

const items: Item[] = [
  {
    image: oddsImage,
    imageClassName: '-sm:-ml-[50%] w-[200%]',
    title: 'Live Applications',
    value: '30+',
  },
  {
    imageNode: (
      <div className="relative size-full object-cover">
        <Image
          className="absolute top-0 -right-1/2 sm:right-0 max-w-none h-auto w-auto min-w-full min-h-full"
          src={liquidityImage}
          alt=""
        />
        <Image
          className="absolute top-0 -right-1/2 sm:right-0 max-w-none h-auto w-auto min-w-full min-h-full animate-pulse"
          src={liquidityGlowImage}
          alt=""
        />
      </div>
    ),
    imageClassName: '-ml-[50%] w-[200%] h-full last-of-type:animate-pulse',
    title: 'Volume',
    value: '$530M+',
  },
  {
    image: bettorsImage,
    imageClassName: '-sm:-ml-[50%] w-[200%]',
    title: 'Unique Users',
    value: '31,000+',
  },
]

type CardProps = {
  className?: string
  data: Item
  dotId: string
}

const Card: React.FC<CardProps> = (props) => {
  const { className, data, dotId } = props
  const { image, imageNode, imageClassName, value, title } = data

  const [ ref, entry ] = useEntry({
    observerProps: {
      rootMargin: '-10%',
    },
  })

  const isScrollingVisible = Boolean(entry?.isIntersecting)

  useEffect(() => {
    if (isScrollingVisible) {
      const dotEl = document.getElementById(dotId)
      dotEl?.classList.add('opacity-100')
      dotEl?.classList.remove('opacity-30')

      return () => {
        dotEl?.classList.add('opacity-30')
        dotEl?.classList.remove('opacity-100')
      }
    }
  }, [ isScrollingVisible ])

  const rootClassName = clsx(
    'snap-center relative w-[21.5rem] h-full sm:w-full sm:h-auto sm:flex-1 flex items-end -sm:rounded-8 bg-grey-90 text-white overflow-hidden',
    'xl:w-[45rem] xl:mr-auto',
    'sm:translate-x-full sm:opacity-0',
    animationClassName,
    className
  )

  return (
    <div
      ref={ref}
      className={rootClassName}
    >
      {
        imageNode ? (
          <div className="absolute top-0 left-0 size-full">
            {imageNode}
          </div>
        ) : (
          <ScrollingBar
            className="absolute top-0 left-0 size-full"
            roundDurationSec={8}
            vertical
            isVisible={isScrollingVisible}
          >
            <Image
              className={clsx(imageClassName, 'w-[200%] sm:w-full max-w-none h-auto')}
              src={image!}
              alt=""
            />
          </ScrollingBar>
        )
      }
      <div className="absolute top-0 left-0 size-full bg-gradient-to-t from-deep-blue2" />
      <div className="z-0 w-full mt-auto pb-12 pr-7 pl-4 sm:pl-12 sm:flex items-baseline sm:justify-between">
        <div className="text-land-h4 mt-5">{title}</div>
        <div className="text-land-h2 mt-5">{value}</div>
      </div>
    </div>
  )
}

type CardsProps = {
  className?: string
}

const cardClassNameByIndex = [ 'delay-100', 'delay-200', 'delay-300' ]

const Cards: React.FC<CardsProps> = (props) => {
  const { className } = props

  const dotsId = useId()

  const carouselClassName = clsx(
    'h-[30rem] grid auto-cols-[21.5rem] -sm:snap-x -sm:px-4 snap-mandatory grid-flow-col gap-x-2 sm:gap-y-[2px]',
    'sm:flex sm:flex-col sm:h-full',
    'touch-manipulation no-scrollbar -sm:overflow-x-auto sm:overflow-x-visible overflow-y-hidden'
  )

  return (
    <div className={clsx(className, 'relative -sm:pb-5 sm:h-full overflow-hidden')}>
      <div className={carouselClassName}>
        {
          items.map((data, index) => {

            return (
              <Card
                className={cardClassNameByIndex[index]}
                key={data.title}
                data={data}
                dotId={`${dotsId}-${index}`}
              />
            )
          })
        }
      </div>
      <div className="w-full absolute bottom-0 left-0 flex items-center justify-center space-x-2 sm:hidden">
        {
          items.map((data, index) => {

            return (
              <div
                key={data.title}
                id={`${dotsId}-${index}`}
                className="size-[6px] bg-deep-blue2 rounded-full opacity-30"
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default Cards
