import clsx from 'clsx'

import { Logo } from 'components/ui'

import { Nav, Backers } from './components'


const year = new Date().getFullYear()

type FooterProps = {
  className?: string
  contentClassName?: string
  withGradientBg?: boolean
  withRoundTop?: boolean
  withBackers?: boolean
}

const Footer: React.FC<FooterProps> = (props) => {
  const { className, contentClassName, withGradientBg, withRoundTop, withBackers } = props

  const rootClassName = clsx(className, 'relative pt-10 text-azuro-sky text-land-body-2', {
    'rounded-t-8': withRoundTop,
    'overflow-hidden': withGradientBg && withRoundTop,
  })

  return (
    <footer className={rootClassName}>
      {
        withGradientBg && (
          <>
            <div className="absolute top-0 left-0 size-full -z-10 -scale-y-100 bg-cover bg-back-gradient-cover" />
            <div className="absolute top-0 left-0 size-full -z-10 bg-deep-blue2 opacity-70" />
          </>
        )
      }
      {
        withBackers && (
          <Backers className="w-container" />
        )
      }
      <div className={clsx(contentClassName, 'mt-14 sm:mt-32 px-4 sm:px-8 pb-6 flex items-start flex-wrap')}>
        <div className="flex-auto -sm:mb-20 min-w-[50%] text-center">
          <Nav className="sm:w-full" />
        </div>
        <div className="sm:-order-1 flex-auto">
          <Logo className="h-5 sm:h-10 fill-white" fillInherit="text" />
        </div>
        <div className="opacity-70 w-1/2 -sm:text-right sm:mt-32">&copy; {year} AZURO</div>
      </div>
    </footer>
  )
}

export default Footer
