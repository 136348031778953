'use client'

import clsx from 'clsx'

import { Title, Cards } from './components'


type ScreenStatsProps = {
  className?: string
}

const animationClassName = 'will-change-transform sm:transform-gpu duration-1000 delay-500 [.section.active_&]:transform-none [.section.active_&]:opacity-100'

const ScreenStats: React.FC<ScreenStatsProps> = (props) => {
  const { className } = props

  const titleRootClassName = clsx(
    'xl:w-[45rem] ml-auto size-full flex items-center',
    '-translate-x-1/2 opacity-0 scale-90',
    animationClassName
  )

  return (
    <section className={className}>
      <div className="sm:h-screen flex -sm:flex-col">
        <div className="flex-none relative -sm:pt-24 -sm:pb-5 px-4 sm:px-3vw xl:px-0 sm:h-full sm:flex items-center sm:w-1/2">
          <div className="absolute top-0 left-0 size-full -z-20 bg-white" />
          <div className="-sm:hidden absolute top-0 left-0 size-full -z-10 bg-dots-blue-25 bg-size-5 bg-bottom mask-gradient-to-t from-black to-95%" />
          <div className={titleRootClassName}>
            <Title />
          </div>
          {/*<p className="mt-10 w-full sm:absolute bottom-0 left-0 sm:pb-5 sm:px-3vw xl:px-0 text-land-body-1 text-brand-50">*/}
          {/*  Azuro is the tooling, oracle & liquidity solution for EVM chains to support thriving prediction & game app ecosystems.*/}
          {/*</p>*/}
        </div>
        <div className="flex-none -sm:mt-12 -sm:pb-16 sm:w-1/2 sm:h-full sm:bg-deep-blue2">
          <Cards />
        </div>
      </div>
    </section>
  )
}

export default ScreenStats
