'use client'

import clsx from 'clsx'

import { VideoOverlay } from 'components/ui'
import { Button } from 'components/inputs'

import PillCarousel from '../PillCarousel/PillCarousel'


type TitleProps = {
  className?: string
}

const Badge = () => {
  return (
    <span className="inline-flex relative w-40 h-14 sm:w-48 sm:h-16 bg-deep-blue2 rounded-full overflow-hidden" aria-hidden="true">
      <VideoOverlay className="absolute top-0 left-0 size-full" />
      <PillCarousel className="size-full" />
    </span>
  )
}

const Title: React.FC<TitleProps> = (props) => {
  const { className } = props

  const rootClassName = clsx(className, 'relative w-full text-[2.7rem] sm:text-[5.5vw] xl:text-[4.5rem] font-poppins font-bold text-grey-90 leading-none tracking-tighter uppercase')

  return (
    <div className={rootClassName}>
      <Badge /><br />
      <span>Where convictions</span><br />
      <span>come to play</span>,<br />
      <em className="bg-back-animated bg-cover bg-clip-text text-transparent pr-1">onchain</em><br />
      <Button
        className="!bg-grey-70"
        title="AZUROSCAN (SOON)"
        styling="land-black"
        rightIcon="interface/arrow-right"
        size={48}
        disabled
      />
    </div>
  )
}

export default Title
