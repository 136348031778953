'use client'

import clsx from 'clsx'

import RandomAvatar from 'compositions/landing/RandomAvatar/RandomAvatar'


const Title: React.FC = () => {

  const rootClassName = clsx(
    'w-full text-[4rem] font-poppins font-bold leading-none tracking-tighter uppercase',
    'sm:flex justify-center text-center sm:text-[9vw] xl:text-[8rem]',
    'bg-back-animated bg-cover bg-clip-text text-transparent text-center',
    'will-change-transform sm:transform-gpu translate-y-full scale-90 duration-1000 delay-500',
    '[.section.active_&]:transform-none'
  )

  return (
    <h2 className={rootClassName}>
      <div className="flex items-center xl:w-1/2 xl:justify-around">
        <RandomAvatar
          className="-sm:hidden mr-2 sm:size-[7.5vw] xl:size-24 size-full"
          selection={[ 0, 5, 1, 7 ]}
        />
        <span>
          Help
        </span>
        <RandomAvatar
          className="mx-2 size-20 sm:size-[7.5vw] xl:size-24"
          selection={[ 2, 3, 0, 1 ]}
          initialBgRotateDeg={135}
          rotateReverse
        />
        <span>
          Us
        </span>
      </div>
      <div className="flex items-center xl:w-1/2 xl:justify-around">
        <RandomAvatar
          className="-sm:hidden mx-2 sm:size-[7.5vw] xl:size-24"
          selection={[ 4, 6, 2, 3 ]}
          initialBgRotateDeg={90}
        />
        <span>
          Grow
        </span>
        <RandomAvatar
          className="mx-2 size-20 sm:size-[7.5vw] xl:size-24"
          selection={[ 7, 4, 5, 6 ]}
          initialBgRotateDeg={180}
          rotateReverse
        />
      </div>
    </h2>
  )
}

export default Title
