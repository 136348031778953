'use client'

import clsx from 'clsx'
import { constants } from 'helpers'

import { Button, buttonMessages } from 'components/inputs'
import { VideoOverlay } from 'components/ui'

import { Title, Labels } from './components'


type ScreenScoreProps = {
  className?: string
}

const animationClassName = 'will-change-transform sm:transform-gpu delay-500 duration-1000 [.section.active_&]:transform-none [.section.active_&]:opacity-100'

const ScreenScore: React.FC<ScreenScoreProps> = (props) => {
  const { className } = props

  const leftTitleClassName = clsx(
    'relative w-full text-land-h1 text-white',
    'font-poppins font-bold leading-none tracking-tighter uppercase word-spacing-max'
  )

  const titleBoxClassName = clsx(
    'xl:w-[45rem] ml-auto size-full flex items-center',
    'translate-y-1/2 opacity-0 scale-90',
    animationClassName
  )

  const rightSideClassName = clsx(
    'flex-none relative -sm:pt-12 sm:w-1/2 sm:h-full px-6 sm:px-3vw xl:px-0 xl:w-[45rem] xl:mr-auto bg-white sm:flex items-center justify-center',
    'translate-x-1/2 opacity-0 scale-110',
    animationClassName
  )

  return (
    <section className={clsx(className, 'flex -sm:flex-col overflow-x-hidden')}>
      <div className="min-h-dvh sm:h-dvh flex -sm:flex-col overflow-x-hidden">
        <div className="flex-none relative px-4 sm:px-3vw xl:px-0 py-24 -sm:pb-16 sm:h-full sm:flex items-center sm:w-1/2">
          <VideoOverlay className="absolute top-0 left-0 size-full -z-10" />
          <div className="absolute top-0 left-0 size-full -z-10 bg-dots-blue-25 bg-size-5 bg-bottom mask-gradient-to-t from-black to-95%" />
          <div className={titleBoxClassName}>
            <div className={leftTitleClassName}>
              RIDE HIGH, <span className="word-spacing-normal">ON THE</span> AZURO
              <div className="text-azuro-yellow">WAVES</div>
            </div>
          </div>
        </div>
        <div className={rightSideClassName}>
          <div className="w-full max-w-[36rem] mx-auto -sm:pb-16">
            <Title />
            <Labels />
            <p className="mt-10 text-brand-50 text-land-body-1 max-w-96">
              Azuro Waves is the program distributing Community Airdrop 2
            </p>
            <Button
              className="mt-7"
              title={buttonMessages.learnMore}
              to={`${constants.links.app}/waves`}
              styling="land-black"
              size={48}
              rightIcon="interface/arrow-right"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ScreenScore
