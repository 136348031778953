'use client'

import { Message } from '@locmod/intl'
import { clsx } from 'clsx'
import { constants } from 'helpers'

import { ScrollingBar } from 'components/dataDisplay'
import { buttonMessages } from 'components/inputs'
import { Href } from 'components/navigation'
import { Icon, VideoOverlay } from 'components/ui'

import useFirstRender from 'views/HomePage/utils/useFirstRender'

import { Title } from './components'


type Screen0Props = {
  className?: string
}

const Screen0: React.FC<Screen0Props> = (props) => {
  const { className } = props

  const isVisible = useFirstRender()

  const bottomSectionClassName = clsx(
    'absolute bottom-0 left-0 w-full h-[38vh] z-10 py-4 px-5 sm:px-3vw text-white rounded-t-8 sm:rounded-t-16 overflow-hidden',
    'will-change-transform sm:transform-gpu duration-1000 delay-200 translate-y-full opacity-0',
    isVisible && '[.section.active_&]:transform-none [.section.active_&]:opacity-100'
  )

  return (
    <section className={clsx(className, 'flex flex-col z-0')}>
      <div className="h-dvh flex flex-col">
        <div className="relative w-full pb-6 flex-1 flex items-end sm:px-3vw xl:px-0">
          <div className="absolute top-0 left-0 -z-10 size-full bg-dots-blue-25 bg-size-5 bg-bottom mask-gradient-to-t from-black to-95%" />
          <Title isVisible={isVisible} />
        </div>
        <div className="relative bg-dots-blue-25 bg-size-5 bg-top h-[38vh]">
          {/*<Transition*/}
          {/*  as="div"*/}
          {/*  className="w-full h-[43vh] pb-[28vh] bg-azuro-sky will-change-transform sm:transform-gpu"*/}
          {/*  enter="duration-1000 delay-100"*/}
          {/*  enterFrom="translate-y-full opacity-30"*/}
          {/*  show={isVisible}*/}
          {/*>*/}
          {/*  <ScrollingBar contentClassName="text-[3.15rem] sm:text-[7.75vw] font-poppins font-bold uppercase leading-[15vh] flex text-white opacity-50" roundDurationSec={60}>*/}
          {/*    <em className="px-16">Apps</em>*/}
          {/*    <em className="px-16">Games</em>*/}
          {/*    <em className="px-16">Liquidity</em>*/}
          {/*    <em className="px-16">Sports Oracle</em>*/}
          {/*  </ScrollingBar>*/}
          {/*  /!*<SlotsOddsBar isVisible={isVisible} />*!/*/}
          {/*</Transition>*/}
          <div className={bottomSectionClassName}>
            <VideoOverlay className="absolute top-0 left-0 w-full h-screen -z-20 -scale-y-100" />
            <div className="absolute bottom-0 left-0 size-full bg-gradient-to-t from-[#030c6a] -z-10" />
            <div className="absolute top-0 left-0 size-full rounded-t-8 sm:rounded-t-16 overflow-hidden -z-10 bg-deep-blue/20 bg-dots-blue-25 bg-size-5 bg-top" />
            <div className="w-container h-full flex -sm:flex-col items-center">
              <div className="sm:w-2/3 max-w-[40rem] -sm:pt-8 text-land-h3 leading-tight normal-case -sm:flex-1">
                Azuro is the prediction markets protocol built for scale
              </div>
              <Href
                className="block ml-auto py-8 group sm:absolute w-1/3 top-0 right-0 sm:px-3vw xl:lef-2/3 xl:text-center sm:h-full text-right hocus:bg-gradient-blue-fade-to-l"
                to="/ecosystem"
              >
                <div className="inline-flex h-full items-center itasems-start sm:pr-6">
                  <Message value={buttonMessages.explore} className="text-land-h3" />
                  <Icon
                    className="size-6 ml-2 -mt-1 sm:size-8 sm:ml-4 sm:group-hocus:translate-x-2 transition"
                    name="interface/arrow-right"
                  />
                </div>
              </Href>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Screen0
