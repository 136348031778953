'use client'
import { type ReactNode, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { debounce } from 'helpers'

import s from './ScrollingBar.module.scss'


type ScrollingBarProps = {
  className?: string
  contentClassName?: string
  roundDurationSec?: number
  inverse?: boolean
  vertical?: boolean
  isVisible?: boolean
  children: ReactNode
}

const ScrollingBar: React.FC<ScrollingBarProps> = (props) => {
  const { children, className, contentClassName, roundDurationSec = 4, inverse, vertical, isVisible = true } = props

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isVisible) {
      ref.current?.classList.remove(s.bar)

      return
    }

    let isMounted = true
    const content = ref.current

    const handler = debounce(() => {
      if (!isMounted || !content) {
        return
      }

      const maxPosition = vertical
        ? -1 * (content.scrollHeight - content.parentElement!.clientHeight)
        : -1 * (content.scrollWidth - content.parentElement!.clientWidth)

      const startPosition = inverse ? maxPosition : 0
      const endPosition = inverse ? 0 : maxPosition

      content.style.setProperty(vertical ? '--start-y' : '--start-x', `${startPosition}px`)
      content.style.setProperty(vertical ? '--end-y' : '--end-x', `${endPosition}px`)
      content.style.setProperty('--duration', `${roundDurationSec}s`)

      content.classList.add(s.bar)
    }, 150)

    handler()

    let resizeObserver: ResizeObserver

    try {
      if (typeof ResizeObserver !== 'undefined') {
        resizeObserver = new ResizeObserver(handler)
        resizeObserver.observe(content?.parentElement!)
      }
    }
    catch (error: any) {}


    return () => {
      isMounted = false
      handler.cancel()

      if (resizeObserver) {
        resizeObserver.unobserve(content?.parentElement!)
        resizeObserver.disconnect()
      }

      content?.classList.remove(s.bar)
    }
  }, [ inverse, roundDurationSec, isVisible ])

  return (
    <div className={clsx(className, 'overflow-hidden')}>
      <div ref={ref} className={clsx(contentClassName, vertical ? 'min-h-fit' : 'min-w-fit whitespace-nowrap')}>
        {children}
      </div>
    </div>
  )
}

export default ScrollingBar
