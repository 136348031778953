import { type CSSProperties, memo } from 'react'
import clsx from 'clsx'
import signImage from 'public/images/logo-sign.svg?url'


import s from './Labels.module.scss'


type ItemProps = {
  className?: string
  rotate?: number
  scale?: number
  value?: string
  left?: string
  right?: string
  top?: string
  rotateDelta?: number
}

const Item: React.FC<ItemProps> = memo((props) => {
  const { className, value, scale = 1, rotate = 0, top, right, left, rotateDelta = 1 } = props

  const rootClassName = clsx(
    className,
    s.animate,
    'absolute p-2.5 sm:p-3 text-land-h3 flex items-center text-white rounded-full',
    'bg-gradient-to-br from-grey-90 via-10% via-brand-50 to-cyan-300'
  )

  const style: CSSProperties = {
    '--rotate-start': `${rotate}deg`,
    '--scale': scale,
    '--delta': rotateDelta,
    top,
    right,
    left,
  }

  return (
    <div className={rootClassName} style={style}>
      <div className="px-3">{value}</div>
      <div className="rounded-full size-9 p-1.5 pl-2 sm:w-16 sm:h-16 sm:p-2 sm:pl-3 bg-white">
        <img className="size-full" src={signImage.src} alt="" />
      </div>
    </div>
  )
})

const items: ItemProps[] = [
  {
    className: '-sm:-top-16 sm:top-4',
    value: 'X3',
    rotate: 12,
    scale: 0.5,
    left: '4rem',
    rotateDelta: -1.5,
  },
  {
    value: 'X2',
    right: '0%',
    top: '9%',
    rotate: -3,
    rotateDelta: 1,
    scale: 0.85,
  },
  {
    value: '+35',
    left: '-3%',
    top: '43%',
    rotate: -21,
    rotateDelta: -1,
    scale: 0.7,
  },
  {
    className: 'top-3/4 sm:top-2/3',
    value: 'X5',
    right: '5%',
    rotate: -4,
    rotateDelta: 2,
    scale: 0.5,
  },
  {
    className: '-sm:hidden',
    value: '+55',
    top: '80%',
    right: '50%',
    rotate: 13,
    rotateDelta: -1.5,
    scale: 0.8,
  },
]

const Labels: React.FC = () => {

  return (
    <>
      {
        items.map((item) => (
          <Item
            key={`${item.value}+${item.left}+${item.top}`}
            {...item}
          />
        ))
      }
    </>
  )
}

export default Labels
