'use client'
import { Message } from '@locmod/intl'
import clsx from 'clsx'
import Image, { type StaticImageData } from 'next/image'
import { constants } from 'helpers'
import liqualityImage from 'public/images/icons/circle/circle-liquidity.png'
import toolsImage from 'public/images/icons/circle/circle-tools.png'
import poolsImage from 'public/images/icons/circle/circle-pools.png'

import { buttonMessages } from 'components/inputs'
import { Href } from 'components/navigation'
import { Icon } from 'components/ui'


type Item = {
  image: StaticImageData
  title: string
  text: string
  buttonTitle: Intl.Message
  withAccentColor?: boolean
} & AtLeastOne<{
  to?: string
  toTab?: string
}>

const items: Item[] = [
  {
    image: toolsImage,
    title: 'Tooling',
    text: 'Abstract unnecessary complexities via the AzuroSDK. Modular plug-and-play components allow for expansive feature customization.',
    buttonTitle: buttonMessages.getStarted,
    toTab: constants.links.build,
  },
  {
    image: liqualityImage,
    title: 'Infrastructure',
    text: 'Apps inherit the full might of Azuro’s liquidity pools and oracle feeds. Host markets with a peace of mind.',
    buttonTitle: buttonMessages.getStarted,
    toTab: constants.links.build,
  },
  {
    image: poolsImage,
    title: 'Contracts',
    text: 'Go down the rabbit hole and uncover the distinct components making up Azuro’s architecture.',
    to: `${constants.links.docs}/contracts/overview`,
    buttonTitle: buttonMessages.learnMore,
    withAccentColor: true,
  },
]

type CardsProps = {
  className?: string
}

const cardClassName = clsx(
  'relative group/card p-3 pb-8 overflow-hidden',
  'bg-white bg-gradient-to-r from-light',
  '-sm:first-of-type:rounded-t-6 -sm:last-of-type:rounded-b-6',
  'sm:pt-5 sm:pb-14 sm:pr-5 sm:pl-6 sm:first-of-type:rounded-l-12 sm:last-of-type:rounded-r-12 sm:bg-gradient-to-t'
)

const Cards: React.FC<CardsProps> = (props) => {
  const { className } = props

  return (
    <div className={clsx(className, 'grid grid-cols-1 gap-[2px] sm:grid-cols-3')}>
      {
        items.map(({ image, title, text, buttonTitle, to, toTab, withAccentColor }) => {
          const overlayClassName = clsx(
            'absolute top-0 left-0 size-full bg-gradient-to-bl from-azuro-sky opacity-20',
            'transition origin-top-right scale-x-0 group-hocus/card:scale-x-100 group-hocus/card:opacity-70'
          )
          const learnMoreClassName = clsx(
            '-sm:mt-1 relative h-7 w-32 px-3 flex items-center justify-center rounded-full text-land-h6 text-grey-90 overflow-hidden',
            'transition group-hocus/card:text-white',
            {
              'bg-azuro-sky group-hocus/card:bg-brand-50': withAccentColor,
              'bg-azuro-yellow group-hocus/card:bg-grey-90': !withAccentColor,
            }
          )

          return (
            <Href
              key={title}
              className={cardClassName}
              to={to}
              toTab={toTab}
            >
              <div className={overlayClassName} />
              <div className="relative z-0">
                <div className="flex items-start justify-between">
                  <Image
                    className="size-18"
                    src={image}
                    alt=""
                    draggable="false"
                  />
                  <div className={learnMoreClassName}>
                    <Message className="pt-[1px] text-nowrap" value={buttonTitle} />
                    <div className="relative h-4">
                      <Icon
                        className="ml-1 size-4 top-0 left-0 text-grey-90 group-hocus/card:absolute group-hocus/card:opacity-0 group-hocus/card:translate-x-1 transition"
                        name="interface/arrow-right"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4 sm:mt-12 px-3 text-deep-blue text-land-h5">
                  {title}
                </div>
                <div className="mt-3 px-3 text-grey-90 text-land-body-1" dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </Href>
          )
        })
      }
    </div>
  )
}

export default Cards
