import clsx from 'clsx'
import Image from 'next/image'
import azuroCoinImage from 'public/images/gif/azuro-coin.gif'


const OLetter = () => {
  return (
    <span className="relative inline ml-0.5">
      <span className="sr-only">O</span>
      <Image
        className="w-[0.8em] h-[0.8em]"
        src={azuroCoinImage}
        alt=""
        draggable={false}
        unoptimized
      />
    </span>
  )
}

type TitleProps = {
  className?: string
}


const Title: React.FC<TitleProps> = (props) => {
  const { className } = props

  const rootClassName = clsx(
    className,
    'relative w-full text-land-h1 bg-back-animated bg-cover bg-clip-text text-transparent',
    'font-poppins font-bold leading-none tracking-tighter uppercase word-spacing-max'
  )

  return (
    <div className={rootClassName}>
      <span className="flex items-center whitespace-nowrap">Azur<OLetter /></span>
      <span className="flex items-center justify-end whitespace-nowrap">Waves</span>
    </div>
  )
}

export default Title
