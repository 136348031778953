import { useEffect, useState } from 'react'


const useFirstRender = () => {
  const [ isVisible, setVisibility ] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisibility(true)
    }, 16)
  }, [])

  return isVisible
}

export default useFirstRender
