'use client'

import { useEffect, useState } from 'react'
import clsx from 'clsx'

// import casinoGif from 'public/images/gif/casino-op.gif'
import liquidityGif from 'public/images/gif/liquidity-op.gif'
import oddsGif from 'public/images/gif/odds-op.gif'
import rocketGif from 'public/images/gif/rocket-op.gif'
import sportBookGif from 'public/images/gif/sportsbook-op.gif'
import crystalBallGif from 'public/images/gif/crystal-ball-op.gif'

import ItemsRightCarousel, { type Item } from './components/ItemsRightCarousel/ItemsRightCarousel'
import ItemsLeftPrefixCarousel, { type LeftPrefix } from './components/ItemsLeftPrefixCarousel/ItemsLeftPrefixCarousel'


type TitleCarouselProps = {
  className?: string
}

const items: Item[] = [
  {
    image: sportBookGif,
    title: 'Apps',
  },
  {
    image: crystalBallGif,
    title: 'Predictions',
  },
  {
    image: rocketGif,
    title: 'Games',
  },
  {
    image: oddsGif,
    title: 'Sports Oracle',
  },
  {
    image: liquidityGif,
    title: 'Liquidity',
  },
]

const itemLeftSidePrefixes: LeftPrefix[] = [
  {
    title: 'Build',
    relatedItemIndexes: [ 0, 1, 2 ],
  },
  {
    title: 'Connect',
    relatedItemIndexes: [ 3, 4 ],
  },
]

const lastIndex = items.length - 1

const animationClassName = 'will-change-transform sm:transform-gpu delay-500 duration-1000 opacity-0 [.section.active_&]:transform-none [.section.active_&]:opacity-100'

const TitleCarousel: React.FC<TitleCarouselProps> = (props) => {
  const { className } = props

  const [ activeIndex, setActiveIndex ] = useState(0)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setActiveIndex((value) => value === lastIndex ? 0 : value + 1)
    }, 3000)

    return () => {
      clearInterval(timerId)
    }
  }, [ activeIndex ])

  return (
    <h2 className={clsx(className, 'h-[2.4em] relative sm:h-0 sm:flex items-center justify-between whitespace-nowrap mb-[0.5em]')}>
      <div className={clsx('absolute top-0 left-0 sm:static -translate-x-1/2', animationClassName)}>
        <ItemsLeftPrefixCarousel items={itemLeftSidePrefixes} activeIndex={activeIndex} />
      </div>
      <div className={clsx('absolute top-2/3 left-0 -sm:w-full sm:static flex-1 translate-y-1/2 scale-110', animationClassName)}>
        <ItemsRightCarousel
          items={items}
          activeIndex={activeIndex}
        />
      </div>
    </h2>
  )
}

export default TitleCarousel
