'use client'

import { type ReactNode } from 'react'
import { useClientOnly } from 'hooks'
import dynamic from 'next/dynamic'
import { useMedia } from 'contexts'

import { Preloader } from 'components/ui'
import TopNav from 'compositions/landing/TopNav/TopNav'
import Footer from 'compositions/landing/Footer/Footer'

import { Screen0, ScreenBuild, ScreenStats, ScreenScore, ScreenGovern } from './components'

import type { Sections } from './types'


const sections: Sections = [
  {
    anchor: 'intro',
    Component: Screen0,
  },
  {
    anchor: 'build',
    Component: ScreenBuild,
  },
  {
    anchor: 'stats',
    Component: ScreenStats,
  },
  {
    anchor: 'score',
    Component: ScreenScore,
  },
  {
    anchor: 'grow',
    Component: ({ className }) => (
      <div className={className}>
        <ScreenGovern className="pb-8" />
        <Footer
          className="snap-end -mt-8 z-0"
          contentClassName="w-container"
          withGradientBg
          withRoundTop
          withBackers
        />
      </div>
    ),
  },
] as const

const Loading = () => (
  <div className="fixed top-0 left-0 size-full flex items-center justify-center">
    <Preloader className="w-16 h-16" />
  </div>
)

const Mobile = dynamic(() => import('./views/Mobile/Mobile'), { loading: Loading })
const Desktop = dynamic(() => import('./views/Desktop/Desktop'), { loading: Loading })

const HomePage: React.FC = () => {
  const { isMobileView } = useMedia()
  const isClient = useClientOnly()

  let content: ReactNode

  if (!isClient) {
    content = <Loading />
  }
  else if (isMobileView) {
    content = <Mobile sections={sections} />
  }
  else {
    content = <Desktop sections={sections} />
  }

  return (
    <>
      <TopNav />
      {content}
    </>
  )
}

export default HomePage
